import React, { useState } from 'react';
import './Dashboard.css';
import { Bar } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';

// تسجيل المكونات اللازمة لـ Chart.js
ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

const Dashboard = () => {
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');

  // بيانات وهمية للرسم البياني
  const salesData = {
    labels: ['الأسبوع الأول', 'الأسبوع الثاني', 'الأسبوع الثالث', 'الأسبوع الرابع'],
    datasets: [
      {
        label: 'مبيعات الشهر الحالي',
        data: [1200, 1900, 3000, 5000],
        backgroundColor: '#00BFA6',
      },
    ],
  };

  // بيانات وهمية للطلبات
  const ordersData = [
    { id: '1001', client: 'العميل 1', date: '2024-09-12', total: '300', status: 'جديد', seller: 'متجر الوردة' },
    { id: '1002', client: 'العميل 2', date: '2024-09-11', total: '450', status: 'تم الشحن', seller: 'متجر الشمس' },
    { id: '1003', client: 'العميل 3', date: '2024-09-10', total: '600', status: 'جاري التجهيز', seller: 'متجر النجوم' },
    { id: '1004', client: 'العميل 4', date: '2024-09-09', total: '150', status: 'مكتمل', seller: 'متجر الزهور' },
    { id: '1005', client: 'العميل 5', date: '2024-09-08', total: '200', status: 'ملغي', seller: 'متجر الصقر' },
    { id: '1006', client: 'العميل 6', date: '2024-09-07', total: '500', status: 'سلة متروكة', seller: 'متجر القمر' },
    { id: '1007', client: 'العميل 7', date: '2024-09-06', total: '750', status: 'مردود', seller: 'متجر البحار' },
  ];

  // بيانات وهمية لآخر أحداث المستخدمين
  const userActivities = [
    { username: "المستخدم 1", action: "تسجيل دخول", time: "2024-09-11 12:45 PM", userId: 1 },
    { username: "المستخدم 2", action: "تحديث بيانات", time: "2024-09-12 09:30 AM", userId: 2 },
    { username: "المستخدم 3", action: "حذف حساب", time: "2024-09-13 08:15 AM", userId: 3 },
  ];

  // بيانات المنتجات الوهمية
  const [products, setProducts] = useState([
    { id: 1, name: 'منتج 1', seller: 'تاجر 1', lastUpdate: '2024-09-12', isActive: true },
    { id: 2, name: 'منتج 2', seller: 'تاجر 2', lastUpdate: '2024-09-11', isActive: false },
  ]);

  // وظيفة لتحديث البيانات (مؤقتة للعرض فقط)
  const handleUpdate = () => {
    console.log(`تحديث البيانات من ${startDate} إلى ${endDate}`);
    // هنا يتم استدعاء API لجلب البيانات بناءً على التاريخ المحدد لاحقًا
  };

  // وظيفة لتغيير حالة التفعيل/التعطيل
  const toggleActivation = (id) => {
    setProducts((prevProducts) =>
      prevProducts.map((product) =>
        product.id === id ? { ...product, isActive: !product.isActive } : product
      )
    );
  };

  return (
    <div className="dashboard">
      <div className="card-container">
        <div className="card card-merchant">
          <h3>عدد التجار</h3>
          <p>1500</p>
        </div>
        <div className="card card-sales">
          <h3>المبيعات</h3>
          <p>2.7M</p>
        </div>
        <div className="card card-customers">
          <h3>عدد العملاء</h3>
          <p>7900</p>
        </div>
        <div className="card card-orders">
          <h3>عدد الطلبات</h3>
          <p>4500</p>
        </div>
      </div>

      {/* القسم الأول: رسم بياني لحالة المبيعات وحالة المنتجات */}
      <div className="dashboard-row">
        <div className="chart-card small-card">
          <h3>حالة المبيعات</h3>
          <div className="date-range">
            <label>من: </label>
            <input type="date" value={startDate} onChange={(e) => setStartDate(e.target.value)} />
            <label>إلى: </label>
            <input type="date" value={endDate} onChange={(e) => setEndDate(e.target.value)} />
            <button onClick={handleUpdate}>تحديث</button>
          </div>
          <Bar data={salesData} />
        </div>

        {/* القسم الثاني: حالة المنتجات */}
        <div className="product-status-card small-card">
          <h3>حالة المنتجات</h3>
          <table>
            <thead>
              <tr>
                <th>اسم المنتج</th>
                <th>اسم التاجر</th>
                <th>تاريخ التحديث</th>
                <th>حالة المنتج</th>
                <th>عرض</th>
              </tr>
            </thead>
            <tbody>
              {products.map((product) => (
                <tr key={product.id}>
                  <td>{product.name}</td>
                  <td>{product.seller}</td>
                  <td>{product.lastUpdate}</td>
                  <td>
                    <label className="toggle-btn-wrapper">
                      <input
                        type="checkbox"
                        checked={product.isActive}
                        onChange={() => toggleActivation(product.id)}
                      />
                      <span className="toggle-btn"></span>
                    </label>
                  </td>
                  <td><button className="view-btn">عرض</button></td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>

        {/* القسم الثالث: آخر أحداث المستخدمين */}
        <div className="user-activity-card small-card">
          <h3>آخر أحداث المستخدمين</h3>
          <table>
            <thead>
              <tr>
                <th>اسم المستخدم</th>
                <th>الإجراء</th>
                <th>التوقيت</th>
              </tr>
            </thead>
            <tbody>
              {userActivities.map((activity, index) => (
                <tr key={index}>
                  <td><a href={`/user/${activity.userId}`}>{activity.username}</a></td>
                  <td>{activity.action}</td>
                  <td>{activity.time}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>

      {/* القسم الرابع: حالة الطلبات */}
      <div className="table-card">
        <h3>آخر الطلبات</h3>
        <table>
          <thead>
            <tr>
              <th>رقم الطلب</th>
              <th>اسم العميل</th>
              <th>التاريخ</th>
              <th>إجمالي المبلغ</th>
              <th>حالة الطلب</th>
              <th>اسم البائع</th>
              <th>عرض</th>
            </tr>
          </thead>
          <tbody>
            {ordersData.map((order) => (
              <tr key={order.id}>
                <td>{order.id}</td>
                <td>{order.client}</td>
                <td>{order.date}</td>
                <td>{order.total}</td>
                <td>{order.status}</td>
                <td>{order.seller}</td>
                <td><button className="view-btn">عرض</button></td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default Dashboard;
