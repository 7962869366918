import React, { useState } from 'react';
import axios from 'axios';
import './Login.css';

function Login({ setIsLoggedIn }) {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  const handleLogin = async (event) => {
    event.preventDefault();
    try {
      const response = await axios.post(
        'https://apibac.tmggal.com/dashboard/login/',
        { username, password },  // إرسال البيانات مباشرةً
        {
          headers: {
            'Content-Type': 'application/json',
          },
        }
      );

      console.log(response); // تحقق من استجابة الخادم

      // التحقق فقط من توكن الوصول (Access Token)
      if (response.data.tokens && response.data.tokens.access) {
        // تخزين توكن الوصول في localStorage
        localStorage.setItem('token', response.data.tokens.access);
        console.log('Token stored:', localStorage.getItem('token')); // تحقق من التوكن
        setIsLoggedIn(true);
        window.location.href = '/dashboard'; // توجيه المستخدم بعد تسجيل الدخول
      } else {
        setErrorMessage('اسم المستخدم أو كلمة المرور غير صحيحة');
      }
    } catch (error) {
      console.log(error.response ? error.response.data : error.message);
      setErrorMessage('خطأ في تسجيل الدخول. حاول مرة أخرى.');
    }
  };

  return (
    <div className="login-container">
      <div className="login-card">
        <div className="logo">TMGGAL</div> {/* الشعار في الأعلى */}
        <h2> You are the best </h2>
        <form onSubmit={handleLogin}>
          <div className="input-group">
            <label> Username</label>
            <input
              type="text"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
              required
            />
          </div>
          <div className="input-group">
            <label> password</label>
            <input
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
            />
          </div>
          {errorMessage && <p className="error">{errorMessage}</p>}
          <button type="submit" className="login-button">تسجيل الدخول</button>
        </form>
      </div>
    </div>
  );
}

export default Login;
