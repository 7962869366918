import React from 'react';

function Customers() {
    return (
        <div>
            <h1>العملاء</h1>
        </div>
    );
}

export default Customers;
