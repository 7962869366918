import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom'; // استيراد Link من react-router-dom
import './Merchants.css'; // استيراد ملف التنسيق

const Merchants = () => {
  const [merchants, setMerchants] = useState([
    {
      id: 1,
      name: 'تاجر 1',
      location: 'الرياض',
      isActive: true,
      type: 'فرد',
      mobile: '0501234567',
      taxNumber: '1234567890',
    },
    {
      id: 2,
      name: 'تاجر 2',
      location: 'جدة',
      isActive: false,
      type: 'مؤسسة',
      mobile: '0551234567',
      taxNumber: '',
    },
    // يمكنك إضافة المزيد من التجار هنا
  ]);

  const [filters, setFilters] = useState({
    name: '',
    mobile: '',
    location: '',
    isActive: '',
  });

  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 20;

  // جلب البيانات بناءً على الفلاتر (إضافة جلب البيانات الفعلية إذا كانت مطلوبة)
  useEffect(() => {
    const fetchMerchants = async () => {
      try {
        const response = await fetch('/api/merchants', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(filters),
        });
        const data = await response.json();
        setMerchants(data);
      } catch (error) {
        console.error('Error fetching merchants:', error);
      }
    };

    fetchMerchants();
  }, [filters]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFilters({
      ...filters,
      [name]: value,
    });
  };

  const filteredMerchants = merchants.filter((merchant) => {
    return (
      (!filters.name || merchant.name.includes(filters.name)) &&
      (!filters.mobile || merchant.mobile.includes(filters.mobile)) &&
      (!filters.location || merchant.location.includes(filters.location)) &&
      (!filters.isActive ||
        (filters.isActive === 'مفعل' && merchant.isActive) ||
        (filters.isActive === 'معطل' && !merchant.isActive))
    );
  });

  const totalPages = Math.ceil(filteredMerchants.length / itemsPerPage);
  const indexOfLastMerchant = currentPage * itemsPerPage;
  const indexOfFirstMerchant = indexOfLastMerchant - itemsPerPage;
  const currentMerchants = filteredMerchants.slice(indexOfFirstMerchant, indexOfLastMerchant);

  const nextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const prevPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const toggleActivation = async (id) => {
    const updatedMerchants = merchants.map((merchant) =>
      merchant.id === id ? { ...merchant, isActive: !merchant.isActive } : merchant
    );
    setMerchants(updatedMerchants);

    try {
      const response = await fetch(`/api/merchants/${id}/toggle-activation`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ isActive: !updatedMerchants.find(m => m.id === id).isActive }),
      });
      const data = await response.json();
      if (!data.success) {
        console.error('Error updating merchant status');
      }
    } catch (error) {
      console.error('Error sending toggle activation request:', error);
    }
  };

  const totalMerchants = merchants.length;
  const activeMerchants = merchants.filter((merchant) => merchant.isActive).length;
  const inactiveMerchants = totalMerchants - activeMerchants;

  return (
    <div className="merchants-container">
      <h2>إدارة التجار</h2>

      <div className="stats-container">
        <div className="stat-card">
          <h3>أجمالي التجار </h3>
          <p>{totalMerchants}</p>
        </div>
        <div className="stat-card">
          <h3>عدد التجار النشطين</h3>
          <p>{activeMerchants}</p>
        </div>
        <div className="stat-card">
          <h3>عدد التجار المعطلين</h3>
          <p>{inactiveMerchants}</p>
        </div>
      </div>

      <div className="filter-container">
        <div className="filter-group">
          <label htmlFor="name">اسم التاجر:</label>
          <input
            type="text"
            id="name"
            name="name"
            value={filters.name}
            onChange={handleInputChange}
            placeholder="ابحث عن اسم التاجر"
          />
        </div>
        <div className="filter-group">
          <label htmlFor="mobile">رقم الجوال:</label>
          <input
            type="text"
            id="mobile"
            name="mobile"
            value={filters.mobile}
            onChange={handleInputChange}
            placeholder="ابحث عن رقم الجوال"
          />
        </div>
        <div className="filter-group">
          <label htmlFor="location">مقر التاجر:</label>
          <input
            type="text"
            id="location"
            name="location"
            value={filters.location}
            onChange={handleInputChange}
            placeholder="ابحث عن مقر التاجر"
          />
        </div>
        <div className="filter-group">
          <label htmlFor="isActive">حالة التفعيل:</label>
          <select
            id="isActive"
            name="isActive"
            value={filters.isActive}
            onChange={handleInputChange}
          >
            <option value="">الكل</option>
            <option value="مفعل">مفعل</option>
            <option value="معطل">معطل</option>
          </select>
        </div>
      </div>

      <table className="merchants-table">
        <thead>
          <tr>
            <th>اسم التاجر</th>
            <th>نوع التاجر</th>
            <th>الرقم الضريبي</th>
            <th>رقم الجوال</th>
            <th>مقر التاجر</th>
            <th>حالة التفعيل</th>
            <th>عرض وتحرير</th>
          </tr>
        </thead>
        <tbody>
          {currentMerchants.map((merchant) => (
            <tr key={merchant.id}>
              <td>{merchant.name}</td>
              <td>{merchant.type}</td>
              <td>{merchant.taxNumber ? merchant.taxNumber : 'لا يوجد'}</td>
              <td>{merchant.mobile}</td>
              <td>{merchant.location}</td>
              <td>
                <div className="toggle-btn-wrapper" onClick={() => toggleActivation(merchant.id)}>
                  <input
                    type="checkbox"
                    checked={merchant.isActive}
                    readOnly
                  />
                  <span className="toggle-btn"></span>
                </div>
              </td>
              <td>
                <Link to={`/merchants/${merchant.id}`}>
                  <button className="view-edit-btn">عرض وتحرير</button>
                </Link>
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      <div className="pagination">
        <button onClick={prevPage} disabled={currentPage === 1}>
          الصفحة السابقة
        </button>
        <span>
          {currentPage} - {totalPages}
        </span>
        <button onClick={nextPage} disabled={currentPage === totalPages}>
          الصفحة التالية
        </button>
      </div>
    </div>
  );
};

export default Merchants;
