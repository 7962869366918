import React from 'react';
import './Topbar.css';

const Topbar = ({ toggleSidebar, handleLogout }) => {
  return (
    <div className="topbar">
      <div className="menu-toggle" onClick={toggleSidebar}>
        <span>&#9776;</span> {/* علامة القائمة (الشرطتين) */}
      </div>
      <div className="user-info">
        <span>مرحباً، Sami_admin</span> {/* اسم المستخدم */}
        <button onClick={handleLogout} className="logout-button">تسجيل الخروج</button>
      </div>
    </div>
  );
};

export default Topbar;
