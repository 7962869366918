import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import Sidebar from './components/Sidebar'; // استيراد القائمة الجانبية
import Dashboard from './components/Dashboard'; // استيراد لوحة التحكم
import Login from './components/Login'; // استيراد صفحة تسجيل الدخول
import Topbar from './components/Topbar'; // استيراد الشريط العلوي (Topbar)
import Merchants from './components/Merchants/Merchants'; // استيراد صفحة التجار
import Customers from './components/Customers/Customers'; // استيراد صفحة العملاء
import Orders from './components/Orders/Orders'; // استيراد صفحة الطلبات

function App() {
  const [isLoggedIn, setIsLoggedIn] = useState(false); // حالة تسجيل الدخول
  const [isLoading, setIsLoading] = useState(true); // حالة التحميل
  const [isSidebarOpen, setIsSidebarOpen] = useState(true); // حالة القائمة الجانبية

  // استخدام useEffect للتحقق من التوكن عند تحميل الصفحة لأول مرة
  useEffect(() => {
    const token = localStorage.getItem('token'); // البحث عن التوكن في LocalStorage
    if (token) {
      setIsLoggedIn(true); // تسجيل الدخول إذا تم العثور على التوكن
    }
    setIsLoading(false); // الانتهاء من عملية التحميل
  }, []);

  // وظيفة تسجيل الخروج
  const handleLogout = () => {
    localStorage.removeItem('token'); // إزالة التوكن من LocalStorage
    setIsLoggedIn(false); // تحديث حالة تسجيل الدخول إلى false
  };

  // وظيفة التبديل بين فتح وإغلاق القائمة الجانبية
  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  if (isLoading) {
    return <div>Loading...</div>; // عرض حالة التحميل
  }

  return (
    <Router>
      <div className="app-layout">
        {isLoggedIn && (
          <>
            {/* عرض الشريط العلوي والقائمة الجانبية فقط إذا كان المستخدم مسجل الدخول */}
            <Topbar toggleSidebar={toggleSidebar} handleLogout={handleLogout} />
            <Sidebar handleLogout={handleLogout} isSidebarOpen={isSidebarOpen} />
          </>
        )}
        <div className={`content-area ${isSidebarOpen ? 'sidebar-open' : 'sidebar-closed'}`}>
          <Routes>
            {/* مسار الصفحة الرئيسية */}
            <Route path="/" element={!isLoggedIn ? <Login setIsLoggedIn={setIsLoggedIn} /> : <Navigate to="/dashboard" />} />
            {/* مسار تسجيل الدخول */}
            <Route path="/login" element={!isLoggedIn ? <Login setIsLoggedIn={setIsLoggedIn} /> : <Navigate to="/dashboard" />} />
            {/* مسار لوحة التحكم (Dashboard) */}
            <Route path="/dashboard" element={isLoggedIn ? <Dashboard /> : <Navigate to="/login" />} />
            {/* مسار التجار */}
            <Route path="/merchants" element={isLoggedIn ? <Merchants /> : <Navigate to="/login" />} />
            {/* مسار العملاء */}
            <Route path="/customers" element={isLoggedIn ? <Customers /> : <Navigate to="/login" />} />
            {/* مسار الطلبات */}
            <Route path="/orders" element={isLoggedIn ? <Orders /> : <Navigate to="/login" />} />
            {/* يمكنك إضافة المزيد من المسارات للصفحات الأخرى بنفس الطريقة */}
          </Routes>
        </div>
      </div>
    </Router>
  );
}

export default App;
