import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHome, faUsers, faStore, faShoppingCart, faChartLine, faCog, faMoneyBill, faBell, faTruck, faFileAlt, faChartBar, faSignOutAlt, faBank, faComment } from '@fortawesome/free-solid-svg-icons'; 
import { faProductHunt } from '@fortawesome/free-brands-svg-icons';
import { Link } from 'react-router-dom';  // لإضافة الروابط
import './Sidebar.css'; // استيراد ملف CSS لتنسيق القائمة الجانبية

const Sidebar = ({ handleLogout, isSidebarOpen }) => {
  return (
    <div className={`sidebar ${isSidebarOpen ? 'open' : 'closed'}`}>
      <h2>TMGGAL</h2>
      <ul className="sidebar-list">
        {/* الرابط لصفحة Dashboard */}
        <li>
          <Link to="/dashboard">
            <FontAwesomeIcon icon={faHome} /> 
            <span>الرئيسية</span>
          </Link>
        </li>
        
        {/* الروابط لباقي الصفحات */}
        <li>
          <Link to="/merchants">
            <FontAwesomeIcon icon={faStore} /> 
            <span>إدارة التجار</span>
          </Link>
        </li>
        <li>
          <Link to="/customers">
            <FontAwesomeIcon icon={faUsers} /> 
            <span>إدارة العملاء</span>
          </Link>
        </li>
        <li>
          <Link to="/orders">
            <FontAwesomeIcon icon={faShoppingCart} /> 
            <span>إدارة الطلبات</span>
          </Link>
        </li>
        <li>
          <Link to="/sales">
            <FontAwesomeIcon icon={faChartLine} /> 
            <span>المبيعات</span>
          </Link>
        </li>
        <li>
          <Link to="/products">
            <FontAwesomeIcon icon={faProductHunt} /> 
            <span>إدارة المنتجات</span>
          </Link>
        </li>
        <li>
          <Link to="/marketing">
            <FontAwesomeIcon icon={faBell} /> 
            <span>التسويق</span>
          </Link>
        </li>
        <li>
          <Link to="/payments">
            <FontAwesomeIcon icon={faMoneyBill} /> 
            <span>مستحقات التجار</span>
          </Link>
        </li>
        <li>
          <Link to="/payment-gateways">
            <FontAwesomeIcon icon={faBank} /> 
            <span>بوابات الدفع</span>
          </Link>
        </li>
        <li>
          <Link to="/shipping">
            <FontAwesomeIcon icon={faTruck} /> 
            <span>طرق الشحن</span>
          </Link>
        </li>
        <li>
          <Link to="/content">
            <FontAwesomeIcon icon={faFileAlt} /> 
            <span>تصميم ومحتوى</span>
          </Link>
        </li>
        <li>
          <Link to="/comments">
            <FontAwesomeIcon icon={faComment} /> 
            <span>إدارة التعليقات والرسائل</span>
          </Link>
        </li>
        <li>
          <Link to="/reports">
            <FontAwesomeIcon icon={faChartBar} /> 
            <span>تقارير عامة</span>
          </Link>
        </li>
        <li>
          <Link to="/settings">
            <FontAwesomeIcon icon={faCog} /> 
            <span>إعدادات الموقع</span>
          </Link>
        </li>
        
        {/* الرابط لتسجيل الخروج */}
        <li onClick={handleLogout}>
          <FontAwesomeIcon icon={faSignOutAlt} /> 
          <span>تسجيل الخروج</span>
        </li>
      </ul>
    </div>
  );
};

export default Sidebar;
